import React from 'react'
import PropTypes from 'prop-types'

import Heading from 'src/components/heading/Heading'
import Button from 'src/components/button'

// Styles & Images:
import 'src/components/pages/shared/scss/DownloadSection.scss'

// Main component:
const DownloadSection = ({ page, section, copy, path, background }) => (
  <section className={`x__section-download x__section-download--${background}`}>
    <div className="x__section-download__content">
      <div className="x__section-download__content-container">
        <Heading page={page} section={section} type="h2" className="x__section-download__heading" />
        <Button to={path} copy={copy} size="largest" />
      </div>
    </div>
  </section>
)

// Main component props:
DownloadSection.propTypes = {
  page: PropTypes.string,
  section: PropTypes.string,
  copy: PropTypes.string,
  path: PropTypes.string,
  background: PropTypes.oneOf(['default', 'black', 'transparent']),
}

DownloadSection.defaultProps = {
  background: 'default',
}

export default DownloadSection
