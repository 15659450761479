import { useEffect } from 'react'
import {
  REDDIT_PIXEL_URL,
  REDDIT_PIXEL_ID,
  META_PIXEL_URL,
  META_PIXEL_ID,
  TIKTOK_PIXEL_URL,
  TIKTOK_PIXEL_ID,
} from 'src/constants'

/*
 *
 * Pixels:
 *
 */
// REDDIT
export const useRedditPixel = (url = REDDIT_PIXEL_URL, id = REDDIT_PIXEL_ID) => {
  useEffect(() => {
    /* eslint-disable */
    !(function (w, d, e, v) {
      if (w.rdt) return

      var p = (w.rdt = function () {
        p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments)
      })
      p.callQueue = []

      var t = d.createElement(e)
      t.src = v
      t.async = !0

      var s = d.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', url)
    /* eslint-enable */

    if (!id) {
      console.warn('Reddit pixel: Please provide Reddit Advertiser ID for initializing')
    } else {
      /* eslint-disable no-undef */
      rdt('init', id)
      rdt('track', 'PageVisit')
      /* eslint-enable no-undef */
    }
  }, [])
}
// META
export const useMetaPixel = (url = META_PIXEL_URL, id = META_PIXEL_ID) => {
  useEffect(() => {
    /* eslint-disable */
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return

      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }

      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', url)
    /* eslint-enable */

    if (!id) {
      console.warn(`Meta pixel: Please provide Meta Pixel's ID for initializing`)
    } else {
      /* eslint-disable no-undef */
      fbq('init', id)
      fbq('track', 'PageView')
      /* eslint-enable no-undef */
    }
    // Fallback if js fails
    // <noscript>
    //   <img height="1" width="1" style="display:none" src={`https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`} />
    // </noscript>
  }, [])
}
// TIKTOK
export const useTikTokPixel = (url = TIKTOK_PIXEL_URL, id = TIKTOK_PIXEL_ID) => {
  useEffect(() => {
    /* eslint-disable */
    !(function (w, d, t, v) {
      w.TiktokAnalyticsObject = t
      var ttq = (w[t] = w[t] || [])
      ;(ttq.methods = [
        'page',
        'track',
        'identify',
        'instances',
        'debug',
        'on',
        'off',
        'once',
        'ready',
        'alias',
        'group',
        'enableCookie',
        'disableCookie',
      ]),
        (ttq.setAndDefer = function (t, e) {
          t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
          }
        })
      for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i])
      ;(ttq.instance = function (t) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
          ttq.setAndDefer(e, ttq.methods[n])
        return e
      }),
        (ttq.load = function (e, n) {
          var i = v
          ;(ttq._i = ttq._i || {}),
            (ttq._i[e] = []),
            (ttq._i[e]._u = i),
            (ttq._t = ttq._t || {}),
            (ttq._t[e] = +new Date()),
            (ttq._o = ttq._o || {}),
            (ttq._o[e] = n || {})
          var o = document.createElement('script')
          ;(o.type = 'text/javascript'), (o.async = !0), (o.src = i + '?sdkid=' + e + '&lib=' + t)
          var a = document.getElementsByTagName('script')[0]
          a.parentNode.insertBefore(o, a)
        })
    })(window, document, 'ttq', url)
    /* eslint-enable */

    if (!id) {
      console.warn(`TikTok pixel: Please provide TikTok Pixel's ID for initializing`)
    } else {
      /* eslint-disable no-undef */
      ttq.load(id)
      ttq.page()
      /* eslint-enable no-undef */
    }
  }, [])
}
