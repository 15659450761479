import React from 'react'
import RampLayout from 'src/templates/ramp'
import PageHead from 'src/components/head/PageHead'

const BuyCryptoPage = () => <RampLayout page="buy-crypto" />

export default BuyCryptoPage

// <head> component:
export function Head() {
  return <PageHead page="buy-crypto" />
}
