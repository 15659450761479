const fetchPageData = (page, src, key = null, val = null) => {
  /* Load page-specific meta data for main content pages and templates */
  const PageData = require(`src/data/pages/${src}/${page}.json`)
  const ContentData = require(`src/data/head/default-${src}-tags.json`)
  const DefaultData = require(`src/data/head/default-Contents-tags.json`)

  return val
    ? PageData[key][val] || ContentData[key][val] || DefaultData[key][val]
    : PageData[key] || ContentData[key] || DefaultData[key]
}
export default fetchPageData
