// dependencies.
import React from 'react'
// utils.
import fetchPageData from 'src/js/utils/fetchPageData'

// Helpers:
// Tag type conditional
const isH1 = (type) => type === 'h1'
// Default className values for each type
const defaultClassName = (type) => `x-header__${isH1(type) ? 'heading' : 'subheading'}`

// Main component:
const Heading = ({
  page = 'default',
  src = 'Contents',
  type = 'h1',
  section = 'copy',
  className,
  id,
  children,
  beforeText,
  text,
  afterText,
}) => {
  /* Fetch data depending on the props set */
  const data = fetchPageData(page, src, type, section)
  /*
   * Wrapper:
   *  - Will return either an h1 or h2, depending on the 'type'
   *  - Must have attributes in order to work, can't pass them in the instance
   *  - beforeText and afterText: when json data has HTML siblings in the template
   */
  const Wrapper = ({ children }) => {
    const props = {
      id: id,
      className: className === null ? '' : className || defaultClassName(type),
      children: children,
    }
    if (isH1(type)) {
      return <h1 {...props} />
    } else {
      return <h2 {...props} />
    }
  }
  return (
    <Wrapper>
      {beforeText}
      <span dangerouslySetInnerHTML={{ __html: text || data }} />
      {afterText}
    </Wrapper>
  )
}

export default Heading
